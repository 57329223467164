<script setup lang="ts">
const { loadPayPalNamespace } = usePayPal();

onMounted(async () => {
  const paypal = await loadPayPalNamespace();
  paypal
    .Legal({
      fundingSource: paypal.Legal.FUNDING.PAY_UPON_INVOICE,
    })
    .render("#paypal-legal-container");
});
</script>

<template>
  <div
    id="paypal-legal-container"
    class="p-3 bg-scheppach-primary-100 b b-scheppach-primary-700 rd my-3"
  ></div>
</template>

<style>
#paypal-legal-container a {
  font-weight: bold;
  text-decoration: underline;
  color: var(--scheppach-primary-500);
}
</style>
